<template>
  <base-info-card title="Popular Links">
    <a
      v-for="feature in getFeatures"
      :key="feature.title"
      :text="feature.title"
      class="mb-8 grey--text body-1 d-block text-none"
      :href="feature.href"
      v-html="`> ${feature.title}`"
    />
  </base-info-card>
</template>

<script>
  export default {
    name: 'SectionPopularLinks',

    computed: {
      getFeatures () {
        return [
          {
            title: this.$t('menuUserManual'),
            href: '/docs/user-manual.html',
          },
          {
            title: this.$t('menuDevelopApi'),
            href: '/docs/index.html',
          },
          {
            title: this.$t('archTitle'),
            href: 'index.html#olap-arch',
          },
          // {
          //   title: this.$t('startTitle'),
          //   href: 'index.html#how-to-license',
          // },
          {
            title: 'OlapDB on Github',
            href: 'https://github.com/nvwaonline/olapdb',
          },
        ]
      },
    },

    data: () => ({
    }),
  }
</script>
